.form-container {
  width: 70%;
  background-color: aliceblue;
  margin: 5% auto;
  padding: 20px;
  border-radius: 10px;
}

.modal-form input {
  width: 40%;
  border: 1px solid rgb(183, 183, 183);
  border-radius: 10px;
  text-align: center;
}


@media (max-width: 900px) {
  .modal-form input {
    width: 95%;
  }
}