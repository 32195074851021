.checkList {
  width: 80%;
  margin: 5% auto;
  background-color: aliceblue;
  padding-bottom: 15px;
  position: relative;
  border-radius: 10px;
}

.title {
  color:rgb(14, 127, 133) ;
  padding: 10px;
} 
.checked {
  text-decoration: line-through;
  color: gray;
}

.list-container-div-checked {
  background-color:rgba(108, 108, 108, 0.147);
  border-radius: 10px;
  padding: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
}

.list-container {
  
  width: 70%;
  margin: auto;
  
}

.list-container-div {
  background-color: white;
  border-radius: 10px;
  padding: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px;
}

.list-container-div input {
  transform: scale(1.7);
}
.list-container-div-checked input {
  transform: scale(1.7);
}
.list-container-div span {
  display: inline-block;
  text-align: center;
}


.delete-task {
  background-color: red;
  padding: 8px 8px 8px 8px ;
  border-radius: 10px;
  min-width: 2.5em;
}

.delete-task:hover {
  transform: scale(1.05);
}

.btn-new-task{
  position: absolute;
  top: 20px;
  left: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(14, 127, 133);
  color: white;
}
/*
modal
*/
.modal-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 4;
}

.modal-background {
  width: 100%;
  height: 100vh;
  background-color: rgba(92, 91, 91, .75);
}

.modal-form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  min-height: 6em;
  background-color: aliceblue;
  border-radius: 10px;
}

.modal-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-height: 8em;
}

#inputNewTask {
  border: 1px solid rgb(183, 183, 183);
  border-radius: 10px;
  text-align: center;
  width: 75%;
}

#close-btn {
position: absolute;
top: 7px;
right: 7px;
}

.btn-submit {
  font-size: 1em;
  background-color: rgb(14, 127, 133);
  padding: 10px;
  border-radius: 10px;
}

@media (max-width: 900px) {
  .btn-new-task {
    position: relative;
    display: block;
    margin: 0 auto;
    top: 0;
    left: 0;
  }

  .list-container {
    width: 90%;
  }

  .list-container-div {
    flex-direction: column;
  }
  .list-container-div-checked {
    flex-direction: column;
  }

  .list-container-div span {
    padding: 15px;
  }
  .list-container-div-checked span {
    padding: 15px;
  }

  .modal-form-container {
    min-width: 300px;
  }

  .modal-form  {
    margin-top: 10%;
  }

  .delete-task {
    margin-top: 10px;
  }
}