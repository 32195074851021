.home-container {
  background-color: aliceblue;
  width: 80%;
  margin: 5% auto;
  padding: 20px;
  border-radius: 15px;
  min-height: 40em;
}

.title {
  font-weight: bold;
}

.title, .subtitle {
  text-align: center;
  color: black;
  font-size: 3.5em;
  font-family: 'Sriracha', cursive;
}

.img-container {
  display: flex;
  justify-content: center;
}

.logo-todolist {
  width: 600px;
  padding: 5px;
  margin-top: 3%;
  max-height: 350px;
  border-radius: 15px;
  background-color: #06577A;
}
.logo-todolist img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.home-list  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 7%;
  
}

.home-list li {
  font-size: 3em;
  list-style: none;
  padding: 15px;
  font-family: 'Sriracha', cursive;
  text-align: center;
}

.home-inscription {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  font-size: 2em;
  align-items: center;
  margin-bottom: .5em;
}

.btn-inscription {
  font-size: 1em;
  background-color: rgb(14, 127, 133);
  padding: 10px;
  border-radius: 10px;
}

.btn-inscription:hover {
  /* opacity: 0.9 !important; */
  background-color: rgba(14, 127, 133,0.9) !important;
  scale: 1.02;
  color: aliceblue;
}

@media (max-width: 900px) {
  .img-container {
    flex-direction: column;
    align-items: center;
  }

  .logo-todolist {
    max-height: none;
    width: 90%;
  }

  .home-container {
    font-size: .8em;
  }

  .home-inscription {
    flex-direction: column;
  }
  
  .home-inscription p {
    text-align: center;
    margin-bottom: 10px;
  }

  .subtitle {
    font-size: 3em;
  }

  .home-list {
    padding-left: 0%;
  }
}