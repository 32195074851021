.form-inscription {
  background-color: white;
  width: 30%;
  height: 30em;
  margin: 5% auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
}

.form-inscription input {
  border: 1px solid rgb(187, 187, 187);
  border-radius: 10px;
}

.form-inscription input,.form-inscription button  {
  width: 50%;
  padding: 10px;
}

@media (max-width: 900px) {
  .form-inscription {
    width: 85%;
  }
}