@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=devanagari,latin-ext");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.title-liste {
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 2em;
  color: #fff;
  padding: 15px;
  margin-top: 1em;
}

.my-custom-class {
  display: block;
  max-width: 600px;
  margin: 20px auto;
}
.card {
  border-radius: 10px;
  filter: drop-shadow(0 5px 10px 0 #ffffff);
  /* max-width: 600px; */
  /* width: 100%; */
  height: 100px;
  background-color: #ffffff;
  padding: 20px;
  margin: 20px auto;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: .5s ease-in;
}

.card:hover{
    color: #ffffff;
    transform: scale(1.015);
    background-color: rgb(82, 141, 163);
    transition: 0.4s ease-in;
}

.card:hover h4 {
  color: white;
  transition: 0.4s ease-in;
}


.card h4{
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 1.5em;
    color: #0e7f85;
    transition: 0.4s ease-in;
}

/* 
  custom ui confirm
*/

.custom-ui {
  background-color: aliceblue;
  padding: 20px;
  border-radius: 10px;
}

.custom-ui h1 {
  padding: 5px;
  text-align: center;
  
}
.custom-ui p {
  padding: 12px;
  
}

.btns-confirm {
  display: flex;
  justify-content: space-around;
}

.btn-yes {
  background-color: red;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid grey;
}

.btn-no {
  background-color: rgb(14, 127, 133);
  padding: 10px;
  border-radius: 10px;
  border: 1px solid grey;
}


#noclick {
  position: absolute;
  bottom: 5%;
  right: 5%;
  border: 1px solid gray;
  border-radius: 10px;
  background-color: red;
  padding: 7px;
  color: aliceblue;
}

.Link {
  background-color: rgb(14, 133, 107);
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  display: block;
  width: 10em;
  margin: 5em auto;
  color: #fff;
}


@media (max-width: 900px) {
  .my-custom-class {
    width: 80%;
  }

  #noclick {
    padding: 4px;
  }
}