.header {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  background-color: aliceblue;
  padding: 20px;
  align-items: center;
}

.nav {
  width: 45%;
  display: flex;
  justify-content: space-around;
}

.nav a {
  text-decoration: none;
  font-size: 1.5em;
  font-family: 'Sriracha', cursive;
  color: rgb(14, 127, 133);
  
}

#email, #password {
  margin-right: 10px;
}

/*menu burger
*/

.burger-icon {
  border: 1px solid rgb(14, 127, 133);
  padding: 5px;
  border-radius: 10px;
  color: #06577A;
}
.burger-icon:active {
  margin-top: 1px;
  background-color: rgba(224, 224, 224, 0.37);
}

.burger-list {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: aliceblue;
  border: 1px solid grey;
  padding: 18px;
  border-radius: 10px;
  z-index: 10;
  width: 75%;
}
.burger-list li {
  border: 1px solid #06577A;
  border-radius: 10px;
  margin: 5px;
  color: rgb(14, 127, 133);
}

@media (max-width: 1250px) {
  .nav{
    width: 70%;
  }
}

@media (max-width: 900px) {
  .nav {
    flex-direction: column;
  }
  .nav a {
    font-size: 1.05em;
    max-width: 85%;
  }

  .login button {
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  .header{
    position: relative;
    width: 95%;
    margin: 0 auto;
  }
}